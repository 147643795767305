import store from "@/store";
import {
  CountryCode,
  CreateHdCustomerVehicleRequest,
  HdCustomerVehicle,
  HdCustomerVehicleListResponse,
  MostCommonCountryCodeResponse,
} from "@/core/dataTypes/types";

const getHdCustomerVehicleList = (): Promise<HdCustomerVehicleListResponse> =>
  store.dispatch("vehicle/getHdCustomerVehicleList");

const createHdCustomerVehicle = (
  payload: CreateHdCustomerVehicleRequest
): Promise<void> => store.dispatch("vehicle/createHdCustomerVehicle", payload);

const refreshHdCustomerVehicleList = (): Promise<void> =>
  store.dispatch("vehicle/refreshHdCustomerVehicleList");

const setDefaultHdCustomerVehicle = (payload: string): Promise<void> =>
  store.dispatch("vehicle/setDefaultHdCustomerVehicle", payload);

const deleteHdCustomerVehicle = (payload: string): Promise<void> =>
  store.dispatch("vehicle/deleteHdCustomerVehicle", payload);

const getVehiclePriceCategories = (): Promise<void> =>
  store.dispatch("vehicle/getVehiclePriceCategories");

const setVehicleFormSubmitLoading = (payload: boolean): Promise<boolean> =>
  store.dispatch("vehicle/setVehicleFormSubmitLoading", payload);

const setPlateNumberLoading = (payload: boolean): Promise<boolean> =>
  store.dispatch("vehicle/setPlateNumberLoading", payload);

const getCountryCodes = (): Promise<MostCommonCountryCodeResponse> =>
  store.dispatch("vehicle/getCountryCodes");

const countries = (): CountryCode[] | undefined =>
  store.state.vehicle?.countries;

const countriesLoading = (): boolean | undefined =>
  store.state.vehicle?.countriesLoading;

const hdCustomerVehicleListLoading = (): boolean | undefined =>
  store.state.vehicle?.hdCustomerVehicleListLoading;

const setDefaultHdCustomerVehicleLoading = (): boolean | undefined =>
  store.state.vehicle?.setDefaultHdCustomerVehicleLoading;

const deleteHdCustomerVehicleLoading = (): boolean | undefined =>
  store.state.vehicle?.setDefaultHdCustomerVehicleLoading;

const createHdCustomerVehicleLoading = (): boolean | undefined =>
  store.state.vehicle?.createHdCustomerVehicleLoading;

const hdCustomerVehicleMaxNumber = (): number | undefined =>
  store.state.vehicle?.hdCustomerVehicleMaxNumber;

const hdCustomerVehicleList = (): HdCustomerVehicle[] | undefined =>
  store.state.vehicle?.hdCustomerVehicleList;

const plateNumberValidationLoading = (): boolean | undefined =>
  store.state.vehicle?.plateNumberValidationLoading;

const vehicleFormSubmitLoading = (): boolean | undefined =>
  store.state.vehicle?.vehicleFormSubmitLoading;

export const vehicleFacade = {
  getHdCustomerVehicleList,
  getVehiclePriceCategories,
  getCountryCodes,
  hdCustomerVehicleMaxNumber,
  hdCustomerVehicleList,
  hdCustomerVehicleListLoading,
  countries,
  countriesLoading,
  createHdCustomerVehicle,
  createHdCustomerVehicleLoading,
  refreshHdCustomerVehicleList,
  setDefaultHdCustomerVehicle,
  setDefaultHdCustomerVehicleLoading,
  deleteHdCustomerVehicle,
  deleteHdCustomerVehicleLoading,
  setPlateNumberLoading,
  plateNumberValidationLoading,
  setVehicleFormSubmitLoading,
  vehicleFormSubmitLoading,
};
