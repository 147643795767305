
































































































































































































import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from "@vue/composition-api";
import { i18n } from "@/locales/i18n";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import StickerCard from "@/views/StickerPurchasePage/components/StickerPay/components/StickerPayBasket/components/StickerCard/StickerCard.vue";
import {
  CartElement,
  CartHighwayStickerQueryResponse,
} from "@/core/dataTypes/types";
import { vehicleFacade } from "@/store/modules/vehicle/vehicle.facade";
import router from "@/router";
import { ERoutes } from "@/core/dataTypes/enum";
import { getLanguageStaticRoute } from "@/core/utils/router.util";
import CardSelector from "@/components/forms/CardSelector/CardSelector.vue";
import { userFacade } from "@/store/modules/user/user.facade";

export default defineComponent({
  name: "Dashboard",
  components: {
    CardSelector,
    StickerCard,
    // MainCmsContent,
  },
  setup() {
    const vehicleMaxNumber = computed(vehicleFacade.hdCustomerVehicleMaxNumber);
    const vehicleList = computed(vehicleFacade.hdCustomerVehicleList);
    const postCartHistoryQueryLoading = computed(
      stickerFacade.postCartHistoryQueryLoading
    );
    const hdVehicleSelect = ref();
    const totalRows = ref(0);
    const stickerCardItems = ref<CartElement[] | unknown>();
    const page = ref(1);
    const pageCount = ref(0);
    const hdCustomerVehicleList = ref([]);

    const getHighwayStickerQueryLoading = ref(true);
    const getHdCustomerVehicleListLoading = ref(true);
    const onChangePagination = () => {
      getHighwayStickerQuery(page.value);
    };

    const onNavigationStickerPay = () => {
      router.push({
        path: getLanguageStaticRoute(
          `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_CREATION}`
        ),
        query: { page: "1" },
      });
    };
    const onNavigationVehicleListPage = () => {
      router.push(getLanguageStaticRoute(ERoutes.VEHICLE));
    };
    const onNavigationValidStickerPage = () => {
      router.push(getLanguageStaticRoute(ERoutes.STICKER_VALID));
    };

    const getHighwayStickerQuery = (page: number) => {
      stickerFacade
        .postHighwayStickerQuery({
          queryParams: {
            onlyValidStickers: true,
          },
          paginationParams: {
            rows: 2,
            page: page,
          },
        })
        .then((res: CartHighwayStickerQueryResponse) => {
          stickerCardItems.value = res.rowList?.highwayStickerQueryRow;
          pageCount.value = res.paginationParams.maxPage;
          totalRows.value = res.paginationParams.totalRows;
          getHighwayStickerQueryLoading.value = false;
        });
    };
    const onSelectHdVehicle = () => {
      if (hdVehicleSelect.value.hdVehicleId === "add") {
        router.push(
          getLanguageStaticRoute(`${ERoutes.VEHICLE}/${ERoutes.VEHICLE_NEW}`)
        );
      } else {
        stickerFacade.saveStickerShopCart({
          hdVehicle: hdVehicleSelect.value,
        });
        router.push({
          path: getLanguageStaticRoute(
            `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_CREATION}`
          ),
          query: { page: "2" },
        });
      }
    };

    onMounted(() => {
      onChangePagination();
      vehicleFacade.getHdCustomerVehicleList().then(() => {
        const list = JSON.parse(JSON.stringify(vehicleList.value));
        const vehicleMax = vehicleMaxNumber.value as number;
        if (list.length < vehicleMax) {
          list.push({
            plateNumber: i18n.tc("VEHICLE_LIST_PAGE.BUTTONS.ADD"),
            icon: "mdi-plus",
            hdVehicleId: "add",
          });
        }
        hdCustomerVehicleList.value = list;
        getHdCustomerVehicleListLoading.value = false;
      });
    });

    return {
      page,
      stickerCardItems,
      pageCount,
      hdCustomerVehicleList,
      hdVehicleSelect,
      postCartHistoryQueryLoading,
      totalRows,
      getHdCustomerVehicleListLoading,
      getHighwayStickerQueryLoading,
      onSelectHdVehicle,
      onChangePagination,
      onNavigationStickerPay,
      onNavigationVehicleListPage,
      onNavigationValidStickerPage,
    };
  },
  metaInfo() {
    return {
      titleTemplate: userFacade.notLoggedInUser()
        ? `${i18n.tc("DASHBOARDPAGE.TITLE")} | %s`
        : `${i18n.tc("DASHBOARD_PAGE.TITLE1")} | %s`,
    };
  },
});
